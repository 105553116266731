<template>
	<div style="width: 100vw;height: 100vh;">
		<div style="width: 100%;overflow: hidden;">
			<div>
				<img style="width: 75%;height: 300px;margin-left: 25%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/g.png">
			</div>
			<div style="position: absolute;left: -64px;top: -300px;">
				<img style="width: 290px;height: 980px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/e.png">
			</div>
			<div style="position: absolute;left: -8px;top: -220px;">
				<img style="width: 220px;height: 790px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/d.webp">
			</div>
			<div style="position: absolute;left: 25px;top: 40px;">
				<img style="width: 70px;height: 70px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/a.webp">
			</div>
			<div style="position: absolute;left: 25px;top: 50px;">
				<div class="logbox">
					<div id="tit">
						融恒					</div>
					<div id="txt">
						<p>Main</p>
						<p>Manager</p>
					</div>
				</div>
			</div>
			<div style="position: absolute;left: 25px;top: 50px;">
				<img style="width: 21px;height: 19px;position: absolute;top: 114px;left: 165px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/b.webp">
			</div>
			<div id="imgClass">
				<p class="t">为中小微企业赋能</p>
				<div id="txtbox">
					<p>>>></p>
					<p style="margin-left: 40px;">Empowerment</p>
				</div>
			</div>
			<div id="elbox">
				Main manager Shanghai Technology Co., Ltd
			</div>
			<div style="position: absolute;left: -25px;top: 530px;">
				<img style="width: 100px;height: 200px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/r.png">
			</div>
			<div style="position: absolute;left: -40px;top: 535px;">
				<img style="width: 100px;height: 200px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/f.png">
			</div>
			<div style="position: absolute;left: 40px;top: 614px;z-index: -2;">
				<img style="width: 230px;height: 55px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/j.png">
			</div>
			<div style="position: absolute;left: 33px;top: 610px;z-index: -2;">
				<img style="width: 230px;height: 55px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/c.webp">
			</div>
			<div
				style="color: #FFFFFF; width: 150px;height: 55px; position: absolute;left: 85px;top: 610px;z-index: -2;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<p style="font-size: 18px;font-weight: bold;">关于我们</p>
					<img src="//1.s51i.faiusr.com/4/ADMIABAEGAAgo9Cb6QUo07W62wcwDjgP.png.webp">
				</div>
				<p>About us</p>
			</div>
			<div id="imgbox" style="">
				<img style="float: right;width: 25px;height: 25px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/l.png">
				<img style="margin-top:125px; width: 25px;height: 25px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/l.png">
				<img class="imgbgi"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/k.png">
			</div>
			<div id="abus">
				<p style="text-indent: 2em;">  <span style="font-weight: bold;">融恒上海）科技有限公司</span>是由创业18年的精英团队和从事20年研发经验的专家共同发起创立，是以“云存储，云计算，大数据，AI智能”为核心技术的高科技互联网公司，专注于企业数字化创新和信息化管理，立志为千万家中小微企业提供移动数字化企业SaaS管理云服务平台，持续为中小微企业数字化转型和企业业务上云服务而努力奋斗。
公司产品完全自主研发，拥有完全自主知识产权（29项软件著作权），并通过了信息安全管理体系认证（ISO/IEC 27001:2013）和信息技术服务管理体系认证（ISO/IEC 20000-1:2018）。
产品自推出以来已被多个行业、多个企业使用，每天为众多客户提供良好的服务体验，我们将通过客户的需求不断完善自己的产品及服务，通过客户的不断积累以更加聚焦细分领域及行业，也将不断投入研发力量和不断更新研发技术以期成为行业引领者。
</p>
			</div>
			<div id="yf">
				<div id="yfleft">
					公司旗下八大核心产品
				</div>
				<div id="yfright">
					<ul>
						<li>1·ERP模块（建筑工程、制造加工）</li>
						<li>2·OA办公模块（多行业应用）</li>
						<li>3·商协会管理模块（商会、协会、联合会）</li>
						<li>4·律所OA办公管理模块（律师事务所）</li>
						<li>5·仓储进销存管理模块（贸易、仓储、服装布料）</li>
						<li>6·智能报修模块（物业、生产制造及有产品维护需求的行业）</li>
						<li>7·商超服务管理模块（美容美发、游乐健身、商超零售、棋牌保健等连锁企业）</li>
						<li>8·汽配维修模块（汽配、汽修美容）</li>
					</ul>
				</div>
			</div>
			<div style="z-index: -2;float: right;margin-right: -60px;margin-top: 1000px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/p.png">
			</div>
			<div style="z-index: -2;float: right;margin-right: -120px;margin-top: 997px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/n.png">
			</div>
			<div id="tj">
				<p>团结 · 创新 · 分享 · 共赢</p>
			</div>
			<div id="xx">
				<img
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/m.png">
			</div>
			<div style="position: absolute;top: 1590px;left: -38px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/r.png">
			</div>
			<div style="position: absolute;top: 1595px;left: -50px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/q.png">
			</div>
			<div style="position: absolute;top: 1680px;left: 23px;width: 225px;height: 55px;z-index: -4;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/j.png">
			</div>
			<div
				style="position: absolute;top: 1685px;left: 13px;width: 225px;height: 55px;z-index: -4;background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/c.webp');background-size: 100% 100%;">
			</div>
			<div
				style="color: rgb(255, 255, 255);width: 148px;height: 55px;position: absolute;left: 68px;top: 1687px;z-index: -2;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<p style="font-size: 18px;font-weight: bold;">关注痛点</p>
					<img src="//1.s51i.faiusr.com/4/ADMIABAEGAAgo9Cb6QUo07W62wcwDjgP.png.webp">
				</div>
				<p>Follow pain point</p>
			</div>
			<div id="annoyance">
				<p id="anytit">企业没有系统的烦恼</p>
				<div id="anybox">
					<div id="anybox_flex">
						<p>痛点1</p>
						<p>合同统计不实</p>
					</div>
					<div id="anybox_flex">
						<p>痛点2</p>
						<p>成本统计不实 </p>
					</div>
					<div id="anybox_flex">
						<p>痛点3</p>
						<p>无审批记录相互推诿</p>
					</div>
					<div id="anybox_flex">
						<p>痛点4</p>
						<p>无数据共享股东怀疑</p>
					</div>
					<div id="anybox_flex">
						<p>痛点5</p>
						<p>财务不得力报表失真</p>
					</div>
					<div id="anybox_flex">
						<p>痛点6</p>
						<p>仓库数据不实</p>
					</div>
					<div id="anybox_flex">
						<p>痛点7</p>
						<p>供应商管理混乱</p>
					</div>
					<div id="anybox_flex">
						<p>痛点8</p>
						<p>客户信息丢失</p>
					</div>
					<div id="anybox_flex">
						<p>痛点9</p>
						<p>客户跟踪失控</p>
					</div>
					<div id="anybox_flex">
						<p>痛点10</p>
						<p>员工离职数据丢失</p>
					</div>
					<div id="anybox_flex">
						<p>痛点11</p>
						<p>订制软件太贵</p>
					</div>
					<div id="anybox_flex">
						<p>痛点12</p>
						<p>员工打卡信息不实</p>
					</div>
					<div id="anybox_flex">
						<p>痛点13</p>
						<p>员工混时间监督难</p>
					</div>
					<div id="anybox_flex">
						<p>痛点14</p>
						<p>员工不总结提升难</p>
					</div>
				</div>
			</div>
			<div style="position: absolute;top:2200px;left: -38px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/r.png">
			</div>
			<div style="position: absolute;top: 2205px;left: -50px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/q.png">
			</div>
			<div style="position: absolute;top: 2285px;left: 23px;width: 225px;height: 55px;z-index: -4;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/j.png">
			</div>
			<div
				style="position: absolute;top: 2290px;left: 13px;width: 225px;height: 55px;z-index: -4;background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/c.webp');background-size: 100% 100%;">
			</div>
			<div
				style="color: rgb(255, 255, 255);width: 148px;height: 55px;position: absolute;left: 68px;top: 2295px;z-index: -2;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<p style="font-size: 18px;font-weight: bold;">解决烦恼</p>
					<img src="//1.s51i.faiusr.com/4/ADMIABAEGAAgo9Cb6QUo07W62wcwDjgP.png.webp">
				</div>
				<p>Solve troubles</p>
			</div>
			<div id="trouble">
				<div id="troublebox_left">
					<div id="liu">
						<p>省心</p>
					</div>
					<div id="text" style="color: rgb(0, 137, 123);text-align: left;">
						<p>统计精准</p>
						<p>报表透视</p>
						<p>数据实时共享</p>
						<p>客户、供应商、员工管理明确</p>
						<p>审批流程清晰</p>
					</div>
				</div>
				<div id="troublebox_left" style="margin-left: 0;">
					<div id="text" style="color: rgb(245, 124, 0); text-align: right;">
						<p>用手机随时随地掌控公司</p>
					</div>
					<div id="liu">
						<p>省时</p>
					</div>
				</div>
				<div id="troublebox_left">
					<div id="liu">
						<p>省力</p>
					</div>
					<div id="text" style="color: rgb(171, 71, 188);text-align: left;">
						<p>节省人力、物力</p>
					</div>
				</div>
				<div id="troublebox_left" style="margin-left: 0;">
					<div id="text" style="color: rgb(33, 150, 243); text-align: right;">
						<p>10人版2999元/年</p>
						<p>ERP/OA系统</p>
						<!-- <p>不限客户端</p> -->
					</div>
					<div id="liu">
						<p>省钱</p>
					</div>
				</div>
			</div>
			<div style="z-index: -2;float: right;margin-right: -60px;margin-top: -50px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/p.png">
			</div>
			<div style="z-index: -2;float: right;margin-right: -120px;margin-top: -50px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/n.png">
			</div>
			<div
				style="width: 220px;height: 55px;background-image: url('//14290117.s50i.faiusr.com/4/101/ADIIABAEGAAgvuCbjQYovaSmpAUwtQU4bg.png.webp');position: absolute;top:2895px;left: 132px;background-size: 100% 100%;z-index: -3;color: rgb(255, 255, 255);line-height: 55px;padding-left: 30px;font-size: 14px;text-shadow: rgb(0 0 0) 3px 3px 5px;">
				<p>一站式解决企业烦恼</p>
			</div>
			<div style="position: absolute;top: 2940px;left: -38px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/r.png">
			</div>
			<div style="position: absolute;top: 2940px;left: -50px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/q.png">
			</div>
			<div style="position: absolute;top: 3030px;left: 23px;width: 225px;height: 55px;z-index: -4;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/j.png">
			</div>
			<div
				style="position: absolute;top:3035px;left: 13px;width: 225px;height: 55px;z-index: -4;background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/c.webp');background-size: 100% 100%;">
			</div>
			<div
				style="color: rgb(255, 255, 255);width: 148px;height: 55px;position: absolute;left: 68px;top: 3036px;z-index: -2;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<p style="font-size: 18px;font-weight: bold;">为您赚钱</p>
					<img src="//1.s51i.faiusr.com/4/ADMIABAEGAAgo9Cb6QUo07W62wcwDjgP.png.webp">
				</div>
				<p style="font-size: 14px;">Make money for you</p>
			</div>
			<div id="moneybox">
				<div id="moneybox_box">
					<div id="moneybox_box_left">
						1
					</div>
					<div id="moneybox_box_right">
						<p class="moneybox_box_right_header">无门槛注册</p>
						<p class="moneytext">只要您成为我们的合作伙伴，均可享受我公司的返佣福利。</p>
					</div>
				</div>
				<div id="moneybox_box">
					<div id="moneybox_box_left"
						style="background-image: url(http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/ztw.png);">
						2
					</div>
					<div id="moneybox_box_right">
						<p class="moneybox_box_right_header">无限级裂变</p>
						<p class="moneytext">二级返佣，以人为中心，粉丝即渠道，你的随手分享，给您带来无限财富。</p>
					</div>
				</div>
				<div id="moneybox_box">
					<div id="moneybox_box_left"
						style="background-image: url(http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/zth.png);">
						3
					</div>
					<div id="moneybox_box_right">
						<p class="moneybox_box_right_header">省时省心</p>
						<p class="moneytext">返佣自动到账，无需审批，即成交就可收款。</p>
					</div>
				</div>
			</div>
			<div style="z-index: -2;float: right;margin-right: -60px;margin-top: -80px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/p.png">
			</div>
			<div style="z-index: -2;float: right;margin-right: -120px;margin-top: -83px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/n.png">
			</div>
			<div style="position: absolute;top: 3490px;left: -38px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/r.png">
			</div>
			<div style="position: absolute;top: 3490px;left: -50px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/q.png">
			</div>
			<div style="position: absolute;top: 3580px;left: 23px;width: 225px;height: 55px;z-index: -4;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/j.png">
			</div>
			<div
				style="position: absolute;top: 3575px;left: 13px;width: 225px;height: 55px;z-index: -4;background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/c.webp');background-size: 100% 100%;">
			</div>
			<div
				style="color: rgb(255, 255, 255);width: 148px;height: 55px;position: absolute;left: 68px;top: 3575px;z-index: -2;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<p style="font-size: 18px;font-weight: bold;">需求行业</p>
					<img src="//1.s51i.faiusr.com/4/ADMIABAEGAAgo9Cb6QUo07W62wcwDjgP.png.webp">
				</div>
				<p>Demand industry</p>
			</div>
			<div id="xqbox">
				<div id="xqbox_box">
					<div id="xqbox_box_left" style="text-align: center;line-height: 169px;">
						<img style="width: 96%; height: 97%;"
							src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/imgon.png">
					</div>
					<div id="xqbox_box_right">
						<div id="xqbox_box_right_header">
							1.
						</div>
						<div id="xqbox_box_right_txt">
							<p>建筑、机电、弱电、安防、</p>
							<p>燃气、供水、通讯、装饰、</p>
							<p>园林、消防、幕墙、桩基</p>
						</div>
					</div>
				</div>
				<div id="xqbox_box">
					<div id="xqbox_box_right">
						<div id="xqbox_box_right_header">
							2.
						</div>
						<div id="xqbox_box_right_txt">
							<p>石材、防水、锁具、五金、</p>
							<p>地板、家具、灯具、</p>
							<p>管材、水利、电力。</p>
						</div>
					</div>
					<div id="xqbox_box_left" style="text-align: center;line-height: 169px;">
						<img style="width: 96%; height: 97%;"
							src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/imgtw.png">
					</div>
				</div>
				<div id="xqbox_box">
					<div id="xqbox_box_left" style="text-align: center;line-height: 169px;">
						<img style="width: 96%; height: 97%;"
							src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/imgth.png">
					</div>
					<div id="xqbox_box_right">
						<div id="xqbox_box_right_header">
							3.
						</div>
						<div id="xqbox_box_right_txt">
							<p>超市、汽修美容店、电器销售店、鲜花礼品店、面包烘焙店</p>
						</div>
					</div>
				</div>
				<div id="xqbox_box">
					<div id="xqbox_box_right">
						<div id="xqbox_box_right_header">
							4.
						</div>
						<div id="xqbox_box_right_txt">
							<p>美业理发店、美甲美容店、</p>
							<p>棋牌健身店。</p>
						</div>
					</div>
					<div id="xqbox_box_left" style="text-align: center;line-height: 169px;">
						<img style="width: 96%; height: 97%;"
							src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/imgfr.png">
					</div>
				</div>
				<div style="z-index: -2;float: right;margin-right: -60px;margin-top: -60px;">
					<img style="width: 110px;height: 250px;"
						src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/p.png">
				</div>
				<div style="z-index: -2;float: right;margin-right: -100px;margin-top: 0px;">
					<img style="width: 110px;height: 250px;"
						src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/p.png">
				</div>
				<div style="z-index: -2;float: right;margin-right: -125px;margin-top: 0px;">
					<img style="width: 110px;height: 250px;"
						src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/n.png">
				</div>
			</div>
			<div style="position: absolute;top: 4390px;left: -38px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/r.png">
			</div>
			<div style="position: absolute;top: 4393px;left: -50px;width: 100px;height: 210px;z-index: -3;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/q.png">
			</div>
			<div style="position: absolute;top: 4480px;left: 23px;width: 225px;height: 55px;z-index: -4;">
				<img style="width: 100%; height: 100%;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/j.png">
			</div>
			<div
				style="position: absolute;top: 4484px;left: 13px;width: 225px;height: 55px;z-index: -4;background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/c.webp');background-size: 100% 100%;">
			</div>
			<div
				style="color: rgb(255, 255, 255);width: 148px;height: 55px;position: absolute;left: 68px;top: 4485px;z-index: -2;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<p style="font-size: 18px;font-weight: bold;">客户 安全 服务</p>
					<img src="//1.s51i.faiusr.com/4/ADMIABAEGAAgo9Cb6QUo07W62wcwDjgP.png.webp">
				</div>
				<p style="font-size: 14px;width: 118%;margin-left: -16px;">Customer Security Service</p>
			</div>
			<div id="imgbox" style="position: absolute;top: 4620px;">
				<img style="float: right;width: 25px;height: 25px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/l.png">
				<img style="margin-top:125px; width: 25px;height: 25px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/l.png">
				<img class="imgbgi"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/imgfr.jpg">
				<div
					style="width: 100%;color: #B3B9C4;font-weight: bold; font-size: 13px;text-align: center;position: absolute;top: 60px;">
					<p>融恒用大数据云计算打造数字化管理平台，</p>
					<p>能一站式解决企业烦恼，公司拥有专业的服务团队。</p>
				</div>
			</div>
			<div id="sevbox">
				<div id="sevbox_box">
					<div id="sevbox_box_left">
						<p class="ab" style="color: #F5B829;font-size: 18px;font-weight: bold;">客户：</p>
					</div>
					<div id="sevbox_box_right" style="color: #FF6230;font-weight: bold;">
						<p>皓阳能源 菲帆建筑	</p>
						<p>东派科技 远承建筑</p>
						<p>凯神建设 云再建筑</p>
						<p>晅合咨询 融垚建筑</p>
						<p>松江久福 易工电器</p>
						<p>实景数码 江苏华舜</p>
					</div>
				</div>
				<div id="sevbox_box">
					<div id="sevbox_box_left">
						<p class="ab" style="color: #F5B829;font-size: 18px;font-weight: bold;">安全： </p>
					</div>
					<div id="sevbox_box_right" style="color: #666;">
						<p>阿里云存储</p>
						<p>信息安全体系</p>
						<p>保密合同</p>
					</div>
				</div>
				<div id="sevbox_box">
					<div id="sevbox_box_left">
						<p class="ab" style="color: #F5B829;font-size: 18px;font-weight: bold;">服务：</p>
					</div>
					<div id="sevbox_box_right" style="color: #666;">
						<p>工作时间 7 * 12</p>
					</div>
				</div>
			</div>
			<div id="footerimgbox">
				<p style="color: #FFFFFF;text-shadow: 5px 5px 4px #000000;">全国咨询热线</p>
				<div @click="phonefun"
					style="width: 148px;height: 33px;background-color: #F5B82A;border-radius: 5px;margin: auto;margin-top: 50px;text-align: center;line-height: 33px;font-weight: bold;color: #FFFFFF;">
					400-618-2019
				</div>
			</div>
			<div style="z-index: -2;float: right;margin-right: -60px;margin-top: -50px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/p.png">
			</div>
			<div style="z-index: -2;float: right;margin-right: -120px;margin-top: -50px;">
				<img style="width: 110px;height: 250px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/n.png">
			</div>
			<div style="position: absolute;right: 23px;top: 4848px;z-index: -5;">
				<img style="width: 220px; height: 56px;"
					src="//14290117.s50i.faiusr.com/4/101/ADIIABAEGAAgmfWbjQYo69mvoAMwyAM4cA.png.webp">
			</div>
			<div style="position: absolute;right: 10px;top: 4840px;z-index: -5;">
				<img style="width: 220px; height: 56px;"
					src="//14290117.s50i.faiusr.com/4/101/ADIIABAEGAAgmfWbjQYo0b_g3wMwyAM4cA.png.webp">
			</div>
			<div
				style="color: rgb(255, 255, 255);width: 148px;height: 55px;position: absolute;right: 55px;top: 4840px;z-index: -2;">
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<img src="//14290117.s50i.faiusr.com/4/101/ADIIABAEGAAgmvWbjQYoh87puwUwETgS.png.webp">
					<p style="font-size: 18px;font-weight: bold;">注册试用</p>

				</div>
				<p>Register for trial</p>
			</div>
			<div id="bytnbox">
				<p>融恒体化数字平台，跨平台操作，让您随时随地展开办公。</p>
				<!-- <button type="button"> -->
				<img style="width: 75px; height: 94px;margin-top: 5px;"
					src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/imgcode.png">
				<a style="display: block; color: #FFFFFF;font-weight: bold;width: 285px;height: 50px;background-color: #F8B62C;text-align: center;line-height: 50px;font-size: 20px;margin: auto;margin-top: 20px;"
					href="http://www.rhdgj.com">进入官网</a>
				<a style="display: block; color: #FFFFFF;font-weight: bold;width: 285px;height: 50px;background-color: #BBBBBB;text-align: center;line-height: 50px;font-size: 20px;margin: auto;margin-top: 20px;"
					@click="down">手机下载使用</a>
				<!-- </button> -->
			</div>
			<footers></footers>
			<div style="height: 65px;">

			</div>
			<div id="btnbox">
				<div id="btnbox_left" @click="phonefun">
					<icon-svg class='iconclas' iconClass='shouye' style="font-size: 30px;"></icon-svg>
					<p style="font-size: 14px;">在线咨询</p>
				</div>
				<div id="btnbox_right" @click="zcfun">
					立即注册
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import footers from './FOOTER.vue'
	export default {
		components: {
			footers
		},
		data() {
			return {

			}
		},
		methods: {
			zcfun() {
				window.open('http://rhdgjzc.ronghengzn.com/', '_blank');
			},
			down() {
				var browser = {
					versions: function() {
						var u = navigator.userAgent,
							app = navigator.appVersion;
						return { //移动终端浏览器版本信息
							trident: u.indexOf('Trident') > -1, //IE内核
							presto: u.indexOf('Presto') > -1, //opera内核
							webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
							gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
							mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
							ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
							android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
							iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
							iPad: u.indexOf('iPad') > -1, //是否iPad
							webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
						};
					}(),
					language: (navigator.browserLanguage || navigator.language).toLowerCase()
				}
				console.log(123)
				if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
					if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
						window.location.href = 'https://apps.apple.com/cn/developer/%E5%A4%A7%E6%80%BB%E7%AE%A1%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/id1593564989'
					}
					if (browser.versions.android) {
						this.$router.push('/downAPP')
					}
				}
			},
			phonefun() {
				window.location.href = 'tel:4006182019'
			}
		}
	}
</script>
<style type="text/css" lang="less" scoped="scoped">
	p {
		margin: 0;
	}

	a {
		text-decoration: none;
	}

	.logbox {
		background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/i.png');
		background-size: 100% 100%;
		width: 210px;
		height: 50px;
		position: absolute;
		top: 65px;
		left: -25px;
		display: flex;
		justify-content: space-around;
		align-items: center;

		#tit {
			font-weight: bold;
			color: rgb(240, 183, 55);
			font-size: 1.6rem;
		}

		#txt {
			p {
				margin: 0;
				color: #ffffff;
				font-size: 12px;
			}
		}
	}

	#imgClass {
		width: 300px;
		height: 100px;
		background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/h.png');
		background-size: 100% 100%;
		position: absolute;
		right: 0;
		z-index: -1;

		.t {
			text-align: center;
			margin-top: 15px;
			color: #FFFFFF;
			font-size: 24px;
			margin-left: 70px;
		}

		#txtbox {
			display: flex;
			color: #FFFFFF;
			margin-left: 70px;
			font-size: 18px;
		}
	}

	#elbox {
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: flex-end;
		color: #666666;
		margin-top: 140px;
		font-size: 12px;
		padding-right: 10px;
	}

	#imgbox {
		width: 90%;
		margin-left: 5%;
		height: 150px;
		position: absolute;
		top: 700px;

		.imgbgi {
			position: relative;
			width: 96%;
			height: 93%;
			top: -145px;
			left: 2%;
		}
	}

	#abus {
		position: absolute;
		top: 870px;
		width: 90%;
		margin-left: 5%;
		font-size: 14px;
		color: #666;
	}

	#yf {
		position: absolute;
		top: 1200px;
		width: 90%;
		margin-left: 5%;
		display: flex;
		font-weight: bold;

		#yfleft {
			width: 30%;
			color: rgb(245, 184, 41);
		}

		#yfright {
			width: 70%;
			font-size: 15px;
			color: rgb(245, 184, 41);
		}
	}

	ul {
		list-style: inside
	}

	#tj {
		width: 300px;
		height: 80px;
		background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/o.png');
		position: absolute;
		top: 1563px;
		left: 66px;
		background-size: 100% 100%;
		z-index: -3;

		p {
			color: #FFFFFF;
			line-height: 80px;
			margin-left: 30%;
			font-size: 14px;
			text-shadow: 4px 3px 3px #060606;
		}
	}

	#xx {
		width: 193px;
		height: 60px;
		position: absolute;
		top: 1583px;
		left: 0px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	#annoyance {
		width: 100%;
		// height: 60px;
		// background-color: #FF0000;
		margin-top: 1260px;

		#anytit {
			font-size: 30px;
			font-weight: bold;
			text-align: center;
			color: rgb(245, 184, 41);
		}

		#anybox {
			width: 60%;
			margin-left: 20%;

			#anybox_flex {
				display: flex;
				justify-content: space-between;
				height: 30px;
				font-weight: bold;
				color: #666;
				font-size: 14px;
			}
		}
	}

	#trouble {
		width: 100%;
		margin-top: 135px;

		#troublebox_left {
			width: 90%;
			margin-left: 5%;
			height: 110px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;

			#liu {
				width: 125px;
				height: 110px;
				background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/LIU.png');
				background-size: 100% 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				color: #FFFFFF;
			}

			#text {
				font-size: 15px;
				min-width: 60%;
				font-weight: bold;
			}
		}
	}

	#moneybox {
		width: 100%;
		margin-top: 245px;

		#moneybox_box {
			width: 90%;
			height: 115px;
			margin-left: 5%;
			display: flex;
			justify-content: space-between;
			margin-top: 20px;

			#moneybox_box_left {
				width: 115px;
				height: 115px;
				background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/zon.png');
				background-size: 100% 100%;
				font-size: 30px;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
				text-shadow: 4px 6px 4px #000;
				font-weight: bold;
			}

			#moneybox_box_right {
				width: 60%;

				.moneybox_box_right_header {
					width: 100%;
					height: 30px;
					background-color: #F4BA28;
					padding-left: 8px;
					color: #FFFFFF;
					font-weight: bold;
				}

				.moneytext {
					font-size: 15px;
					color: #666;
				}

			}
		}
	}

	#xqbox {
		width: 100%;

		#xqbox_box {
			width: 90%;
			margin-left: 5%;
			height: 170px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 30px;

			#xqbox_box_left {
				width: 150px;
				height: 170px;
				background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/bordertw.png');
				background-size: 100% 100%;
			}

			#xqbox_box_right {
				height: 100%;

				#xqbox_box_right_header {
					width: 156px;
					height: 40px;
					border-bottom: 3px solid #F5B82A;
					font-weight: bold;
					line-height: 50px;
				}

				#xqbox_box_right_txt {
					width: 175px;
					font-size: 14px;
					color: #666;
				}
			}
		}
	}

	#sevbox {
		width: 100%;
		// background-color: #0000EE;
		margin-top: 500px;

		#sevbox_box {
			width: 80%;
			min-height: 90px;
			margin-left: 10%;
			display: flex;
			justify-content: flex-start;

			#sevbox_box {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			#sevbox_box_right {
				font-size: 20px;
			}
		}
	}

	#footerimgbox {
		width: 80%;
		height: 200px;
		margin-left: 10%;
		background-color: #FFF000;
		background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/propagandize/propagandize/imgfi.png');
		background-size: 100% 100%;
		border: 10px solid #F5B82B;
	}

	#bytnbox {
		width: 80%;
		margin-left: 10%;
		margin-top: 105px;
		text-align: center;
		margin-bottom: 30px;
	}

	#btnbox {
		width: 100%;
		height: 50px;
		background: linear-gradient(to right, #F09249, #E83E77);
		position: fixed;
		bottom: 30px;
		display: flex;
		justify-content: space-around;
		align-items: center;

		#btnbox_left {
			width: 20%;
			height: 100%;
			text-align: center;
			color: #FFFFFF;
		}

		#btnbox_right {
			width: 70%;
			height: 80%;
			background: linear-gradient(to right, #FDF3DB, #FBE8AB);
			border-radius: 45px;
			text-align: center;
			line-height: 40px;
			color: #E5382C;
			font-weight: bold;
		}
	}
</style>
